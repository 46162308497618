import axios from 'axios';
import appConfig from 'configs/app.config';
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant';
import { PERSIST_STORE_NAME } from 'constants/app.constant';
import deepParseJson from 'utils/deepParseJson';
import store from '../store';
import { onSignOutSuccess } from '../store/auth/sessionSlice';
import jwt from 'jwt-decode';

const unauthorizedCode = [401];

const BaseService = axios.create({
    timeout: 120000,
    baseURL: appConfig.apiPrefix,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
});

const isTokenExpired = (token) => {
    if (!token) {
        return true;
    }

    const decodedToken = jwt(token);
    const expiryDatetime = new Date(decodedToken.exp * 1000);
    return Date.now() > expiryDatetime;
};

const handleRefreshToken = async (oldToken) => {
    store.dispatch(onSignOutSuccess());
};

BaseService.interceptors.request.use(
    async (config) => {
        /* const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
        const persistData = deepParseJson(rawPersistData);
           let accessToken = persistData.auth.session.token
        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }
        */
        let accessToken = JSON.parse(localStorage.getItem('usertoken'));

        //check token expiry
        if (isTokenExpired(accessToken)) {
            accessToken = await handleRefreshToken(accessToken);
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

BaseService.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { response } = error;

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess());
        }

        return Promise.reject(error);
    }
);

export default BaseService;
