import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyC55O-5KN9JwIS79Cg5pL6sHey3rFXX7TE',
    // authDomain: 'rebatus-client-web-application.firebaseapp.com',
    authDomain: 'auth.goaudience.com',
    projectId: 'rebatus-client-web-application',
    storageBucket: 'rebatus-client-web-application.appspot.com',
    messagingSenderId: '922375470107',
    appId: '1:922375470107:web:4ec2f94a5d2a0079d4ccbe',
};
const app = initializeApp(firebaseConfig);
export const firebaseAuthInstance = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
