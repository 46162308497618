import GoogleSvg from 'assets/svg/GoogleSvg';
import Button from '../Buttons';
import { apiGoogleClientExists } from 'services/AuthService';
import { firebaseAuthInstance, googleAuthProvider } from 'utils/firebaseSdk';
import { getAdditionalUserInfo, signInWithPopup } from 'firebase/auth';
import useAuth from 'utils/hooks/useAuth';

const SignInGoogleButton = ({
    title = 'Continue with Google',
    setToken,
    setLoading,
}) => {
    const { signInGoogle } = useAuth();

    const googleSignIn = async () => {
        setLoading(true);
        try {
            const result = await signInWithPopup(
                firebaseAuthInstance,
                googleAuthProvider
            );
            const idToken = await result.user.getIdToken(true);
            localStorage.setItem("idToken",idToken)
            const res = await apiGoogleClientExists(idToken);
            if (!res.data) {
                setToken(idToken);
            } else {
                signInGoogle(idToken, null);
            }
        } catch (error) {
            setLoading(false);
            throw new Error(error);
        }
    };

    return (
        <div className="flex justify-center mt-1 mb-1 bg-[#fff] text-black">
            <button
                type="button"
                onClick={googleSignIn}
                variant="solid"
                className="font-semibold flex items-center justify-center text-black border border-[#EAECF0] w-full py-1 rounded-[12px]"
                style={{
                    boxShadow:"0px 1px 2px 0px #1018280F"
                }}
            >
                <GoogleSvg width={35} height={35} /> {title}
            </button>
        </div>
    );
};

export default SignInGoogleButton;
