import React from 'react';
import Google from 'assets/images/Google_Icons-09-512.webp';

const GoogleSvg = (props) => {
    return <img src={Google} width={props.width} height={props.height} alt="google-ads" />;
};

GoogleSvg.defaultProps = {
    width: 20,
    height: 20,
};

export default GoogleSvg;
