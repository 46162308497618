import { useSelector, useDispatch } from 'react-redux';
import { setUser, initialState } from 'store/auth/userSlice';
import {
    apiGoogleSignin,
    apiSignIn,
    apiSignOut,
    apiSignUp,
} from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import axios from 'axios';
import { FaLess } from 'react-icons/fa';
const API_URL = appConfig.apiPrefix;

function useAuth() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const query = useQuery();

    const { token, signedIn } = useSelector((state) => state.auth.session);

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values);
            if (resp.data) {
                const { refreshToken } = resp.data;
                dispatch(onSignInSuccess(refreshToken));
                localStorage.setItem('usertoken', JSON.stringify(refreshToken));
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                );
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values);
            if (resp.data) {
                const { token } = resp.data;
                dispatch(onSignInSuccess(token));
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(redirectUrl);
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        await apiSignOut();
        localStorage.removeItem("idToken")
        handleSignOut();
    };
    const signInGoogle = async (idToken, companyName) => {
        try {
            const resp = await apiGoogleSignin(idToken, companyName);
            if (resp.data) {
                const { refreshToken } = resp.data;
                dispatch(onSignInSuccess(refreshToken));
                localStorage.setItem('usertoken', JSON.stringify(refreshToken));
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    );
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                );
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            };
        }
    };
    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
        signInGoogle,
    };
}

export default useAuth;
